.smart-loader {
  &__actions {
    display: flex;
    justify-content: center;

    & > div {
      margin: 10px;
    }
  }
}
