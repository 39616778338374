@import "constants";
table.device-list-table {
  padding:0;
  width: 100%;
  background-color: $background-color;
  //margin: 15px 0;
  //padding: 15px;
  //box-shadow: 1px 1px 2px #ccc;

  thead{
    //box-shadow: 1px 1px 2px #ccc;
    tr{
      height: 30px;
    }
  }

  tr {
    height: 60px;
    cursor: pointer;


    td, th {
      padding: 0 12px;
    }

    th{
      height:34px;
    }

    td {
      border-top: 1px solid $background-color-lighter;
    }

    td.row-num {
      width: max-content;
    }
  }
  tbody {

    tr:hover {
      background-color: $background-color-lighter;
    }
  }
}

table.variable-list{
  color: $text-color;
  width:100%;

  tr{
    height: 2rem;

    th {
      font-weight: bold;
      background-color: #e6e6e6;
    }

    td{
      line-height: 18px;
      padding: 0 8px ;
      border: none ;
      vertical-align: middle ;
    }
  }

  tr:nth-child(even) {
    background-color: #f3f3f3;
  }

  tr.editable-row{
    position: relative;

    td.editable-col{
      position: relative;
    }

    button.edit-variable-btn{
      display: none;
      position: absolute;
      top:0;
      right:0;
    }

    &:hover{
      button.edit-variable-btn{
        display: block;
      }
    }

  }

}

div.device-info-eco{
  display: flex;
  flex-direction: column;

  div.info-item{
    margin-bottom: 1.6rem;
    font-weight: bold;
    label{
      font-weight: normal!important;
      margin-right: 1rem;
    }
    display: flex;
    justify-content: space-between;
  }

}

div.device-info {
  border: 1px solid $brand-color;
  padding: 20px 20px 0 20px;
  border-radius: 15px;
  margin: 2rem 0 2rem 0;
  position: relative;
  display: inline-block;
  box-sizing: border-box;

  span.title{
    position: absolute;
    top: -12px;
    background-color: white;
    padding: 0 8px;
    color: $brand-color;
    letter-spacing: 4px;
    font-weight: bold;
    left: 13px;
  }

  p{
    font-size: 90%;
    float: left;
    margin-right: 40px;
    display: flex;
    align-items: center;
    height: 1.4rem!important;

    label {
      margin-right: 10px;
    }
  }
}


ul.select-variable-list{
  padding-left: 1rem;
  list-style: none;
  li{
    display: flex;
    align-items: center;
  }
}