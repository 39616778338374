.simple-dd{
  button.dropdown-toggle{
    background-color: transparent;
    border: none;
    color: white;
  }

  ul.dropdown-menu{
    li{
      cursor: pointer;
    }
  }
}