@import 'constants';


.form-control:focus{
  border-color: #ededed;
  box-shadow: 0 0 0 .25rem rgba(191, 191, 191, 0.25);
}

.input-group{
  .dropdown-container{
    height: inherit;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;

    span.selected-item{
      height: 100%;
      border-radius: 0.35rem;
    }
    .dropdown.open{
      top:100%;
    }

    &.open{
      border-radius: 0.25rem;
    }
  }
}

.input-group.file-uploader{
  height:5rem;
  display: flex;
}

.file-uploader > span.load-icon-preview{
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #ededed;
  border-radius: 0.3rem;
  box-shadow: 0 0 2px #ededed;
}

span.load-icon-preview img{
  width: 100%!important;
  height: 100%!important;
}


//
//
//
//
//.form-group {
//  text-align: left;
//  margin-bottom: 1rem;
//}
//
//.form-group.understroke{
//  border-bottom: 1px solid #d7d7d7;
//}
//
//.form-group.center {
//  text-align: center;
//}
//
//.form-group.right {
//  text-align: right;
//}
//
//.input-group{
//  position: relative;
//  display: flex;
//  flex-wrap: wrap;
//  align-items: stretch;
//  width:100%;
//  //height:1.8rem;
//  height: 2rem;
//}
//.input-group.not-inline > .input-group-prepend{
//  width:100%;
//}
//.input-group.not-inline{
//  height: 4rem;
//}
//
//.input-group > input[type="text"], .input-group > input[type="password"] {
//  /*flex-grow: 1;*/
//  position: relative;
//  -ms-flex: 1 1 0%;
//  flex: 1 1 0%;
//  min-width: 0;
//  color: $text-color;
//  height: 1.8rem;
//  margin: 0;
//  padding: 0 1rem;
//}
//
//.input-group > input[type="text"]:after{
//  content: url('../sources/images/interface/edit.svg');
//  width:1rem;
//  //height:1rem;
//  position:absolute;
//  top:0;
//  right:0;
//}
//
//.input-group-addon {
//  height: inherit;
//}
//.input-group-addon > button {
//  margin: 0;
//  height: 100%;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//}
//
//input:-webkit-autofill{
//  background-color: #ffffff!important;
//  color: #3498db !important;
//  border-radius: 1rem;
//  //height: 0.6rem;
//  margin-top: 0.2rem;
//}
//
//label {
//  display: inline-block;
//  margin-bottom: .2rem;
//  color: #737373;
//  letter-spacing: 0.1rem;
//}
//
//.form-control{
//  display: block;
//  width: 100%;
//  padding: .375rem .75rem;
//  margin: 0!important;
//}
//
//.input-group.file-uploader{
//  height:5rem;
//  display: flex;
//}
//
//.file-uploader > span.load-icon-preview{
//  width: 5rem;
//  height: 5rem;
//  display: flex;
//  justify-content: center;
//  align-content: center;
//  background-color: #ededed;
//  border-radius: 0.3rem;
//  box-shadow: 0 0 2px #ededed;
//}
//
//span.load-icon-preview img{
//  width: 100%!important;
//  height: 100%!important;
//}
//
//input[type=time]{
//  border: 0;
//  color: $brand-color;
//  position: relative;
//  flex: 1 1;
//}
//
//.head-input{
//  width: 70% !important;
//  font-weight: Bold;
//  font-size: 16px;
//  margin: 0 auto;
//}
//
//.mixed-input{
//  display: flex;
//}
//
//

//
//
//
//form {
//  .input-group.row {
//    padding: 0;
//  }
//
//  button, a {
//    margin-left: 1rem;
//  }
//
//  button:first-child, a:first-child {
//    margin-left: 0;
//  }
//
//  label {
//    white-space: nowrap;
//    margin-right: 1rem;
//    color: $text-color;
//  }
//
//  .input-group {
//    flex-wrap: wrap;
//    -ms-flex-align: stretch;
//    width: 100%;
//    position: relative;
//    align-items: center;
//    margin-bottom: 2rem;
//
//    &.in-table{
//      margin-bottom: 0!important;
//
//      input[type="text"]{
//        margin-bottom: 0!important;
//        border: 1px solid grey;
//        padding: 0 1rem;
//        margin: 0 0.5rem;
//        box-sizing: border-box;
//        border-radius: 1rem;
//      }
//    }
//
//    &.textarea {
//      height: fit-content !important;
//    }
//
//    input[type="time"] {
//      border: 0;
//      width: 100%;
//      font-size: 1rem;
//      align-self: center;
//    }
//    input[type="date"] {
//      font-weight: 400;
//      border: 0;
//      width: 100%;
//      font-size: 1rem;
//      align-self: center;
//    }
//
//    input[type="text"] {
//
//      width: auto;
//      font-size: 1rem;
//      align-self: center;
//    }
//
//    .form-control {
//      position: relative;
//      -ms-flex: 1 1 0%;
//      flex: 1 1 0%;
//      min-width: 0;
//      margin-bottom: 0;
//    }
//
//    &.understroke {
//      border-bottom: 1px solid #d7d7d7;
//    }
//
//    .input-group-append, .input-group-prepend {
//      display: flex;
//      align-self: center;
//
//      svg {
//        fill: $text-color;
//        height: 1rem;
//        width: 1rem;
//        pointer-events: none;
//      }
//    }
//
//    &.required:before {
//      content: '*';
//      position: absolute;
//      top: 0;
//      left: -0.5rem;
//      color: $brand-color;
//    }
//
//    .warning-message {
//      display: inline-block;
//      position: absolute;
//      width: 100%;
//      color: #ff2f00;
//      height: 1.25rem;
//      font-size: 80%;
//      top: 2.2rem;
//
//      svg {
//        height: 1.25rem;
//        fill: $warning;
//        margin-right: 0.5rem;
//      }
//
//      div {
//        float: left;
//      }
//
//    }
//  }
//}