@import 'constants';
.tip-wrapper{
    position: absolute;
    top: -60px;
    left: 0;
  width: 100%;
}

.tip-btn{
  text-align: center;
  cursor: pointer;
  position: relative;
  border: 1px solid $brand-color!important;
  border-radius: 14px;
  color: $brand-color;
  height: 28px;
  width: 100%;
  line-height: 28px;
}

.tip-btn:hover{
  background-color: $brand-color-lighter;
  color: white;
  border-color: $brand-color-lighter !important;
}

.tooltip{
  line-height: 20px;
  padding: 10px;
  text-align: justify;
  position: absolute;
  width: 300px;
  background: #fff;
  border: 1px solid $brand-color !important;
  color: $brand-color;
  border-radius: 10px;
  opacity: 0;
  transition: all 0.5s ease;
}

.image-tooltip{
  width: 970px;
}

.right-tooltip{
    right: -360px;
}

.bottom-tooltip{
  left: -30px;
  bottom: -600px;
}

.graph-help-image{
  width: 1000px;
  height: 250px;
}

.tip-btn:hover .tooltip{
  opacity: 1;
}

