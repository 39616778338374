.main-container {
    z-index: 1;
}

.sidebar-container, div.dashboard-list{
    z-index: 3;
}
.navbar-header{
    z-index: 2;
}

button.sidebar-btn > label{
    z-index: 2;
}

button.sidebar-btn > div{
    z-index: 3;
}

div.modal-layout {
    z-index: 1000;
}