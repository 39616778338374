@import "constants";

header.eco-header{
  position:absolute;
  top:0;
  left:0;
  right:0;
  height: 6rem;
  background-color: $brand-color;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color:white;

  //.logo{
  //  svg{
  //    height: 4rem;
  //  }
  //}

  .logo{
    display: flex;
    align-items: center;
    svg{
      height: 3rem;
    }
    label{
      margin-left: 0.8rem;
      font-weight: bold;
      text-align: left;
      color:white;
    }
  }

  ul.header-menu{
    list-style: none;
    display: flex;
    margin: 0;
    padding:0;
    font-family: Montserrat-Regular;
    color:white;
    font-size: 1rem;

    li{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 2rem;
      cursor: pointer;
      box-sizing: border-box;
      a{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none;
      }
      label{
        color:white!important;
      }


      svg{
        height: 2.6rem;
      }

      &:hover{
        label{
          text-shadow: 0px 0px 1px white;
        }
      }

      &.active:before{
        content: ' ';
        width: 6rem;
        position: absolute;
        bottom: 0;
        height: 0.5rem;
        background-color: white;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
      }

    }
  }

  .office{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .personal-office{
      margin-bottom: 0.2rem;
      svg{
        height:2.6rem;
      }
    }

    .clock{
      pointer-events: none;
      color: white;
    }
  }
}