@import 'constants';

ul.eco-list{
  list-style: none;

  li{
    display: flex;
    justify-content: space-between;

    & > *:last-child {
      display: flex;
    justify-content: flex-end;
    }
  }
}

.list-view {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__item {
    display: flex;
    flex-direction: row;
    padding: 10px;
    width: 100%;

    &:hover {
      background-color: #8514da0a;
    }

    &__content {
      margin-right: 20px;
      width: 100%;

      &__name {
        line-height: 0;
        color: $brand-color;
        font-size: 1.2em;
        text-wrap: normal;
      }

      &__number {
        font-weight: bold;
        padding: 4px;
        color: $text-color;
      }

      &__status {
        font-size: 1em;
        color: $text-color;
        font-weight: bold;
      }
    }

    &__actions {
      display: flex;
      flex-direction: row;
      width: 30%;
      
      & > div {
        padding: 5px;
      }
    }

    &__image {
      padding-right: 20px;
      width: 24px !important;
      height: 24px !important;
      fill: $brand-color;
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__view_all {
      color: $text-color;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
