@import 'constants';

.capacity-header {
  display: flex;
  flex-direction: row;
}

.capacity-name {
  margin-top: 5px !important;
}

.capacity-grid {
  //padding-left: 20px;
  //padding-top: 20px;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-y: auto;
  //height: 1000px;


  flex-wrap: wrap;
  align-content: flex-start;

  &::-webkit-scrollbar {
    visibility: hidden;
    width: 0;
  }


}


.capacity-view {
  flex-direction: row;
  width: 100%;
  overflow: hidden;

  .detail-view {
    width: 70%;
  }

  .full-view {
    width: 100%;

  }


}

.capacity-spacer {
  display: flex;
  position: absolute;
  left: 49%;
  top: 50%;
}

.capacity-item {
  height: 180px;
  width: 180px;
  padding: 0.5rem;
  margin: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border: 1px solid white;

  .add-item {
    fill: $brand-color;
    margin-left: auto;
    margin-right: auto;
    height: 64%;
    width: 64%;
  }

  label {
    letter-spacing: unset;
    //white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .status-view {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 16px;
    margin-bottom: 10px;


    img {
      height: 12px;
      width: 12px;
    }

    .status {
      width: 14px;
      height: 14px;
      border-radius: 50%;

      border: 1px solid #FFFBFB;
      box-sizing: border-box;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

      &-active {
        background: green;
      }

      &-disactive {
        background: lightgray;
      }

      &-warn {
        background: yellow;
      }

      &-error {
        background: red;
      }

      &__image {
        width: 16px;
        fill: #ec2929ad;
      }

    }


  }

  .image>img {
    margin: 3px;

    height: 64px !important;
  }
}

.capacity-item:hover {
  border: 1px solid $brand-color;
  //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  transition: box-shadow 0.6s linear;
}

.capacity-item-selected {
  border: 1px solid $brand-color !important;
  border-radius: 15px;
  //background-color: #8514da0a;
}


.capacity-folder {

  box-shadow: 2px 2px 4px #c8c8c8;
  border-radius: 0.5rem;
  border-bottom: 3px #eaeaea double;
  border-right: 3px #eaeaea double;
  border-left: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
}

.capacity-layout-grid {
  width: 100%;
}

.capacity-resize {

  width: 40px;
  height: 40px;
  display: flex;
  padding: 5px;
  align-items: center;
  transform: rotate(180deg);

  &-deactivated {
    width: 40px;
    height: 40px;
    display: flex;
    padding: 5px;
    align-items: center;
  }
}


.capacity-detail {
  padding-top: 10px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  width: 70%;

  &__header {
    display: flex;
    flex-direction: row;

    &__text {
      color: $brand-color;
      font-size: 1.5em;
      font-weight: bold;
      line-height: 1;
    }

    &__selector {
      color: #58595B;
      font-size: 1.2em;
      padding-right: 15px;
    }

    &__actions {

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-left: 20px;
      width: 70px;
      padding-right: 20px;

    }
  }


}