@import "constants";

.passport-view {
  margin-top: 50px;
  margin-left: 30px;
  display: flex;
  height: 720px;
  overflow-y: scroll;
  flex-direction: column;

}

.passport-view-header {
  color: #737373;
  font-size: 1.25rem;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  span {
    color: $brand-color;
    font-weight: bold;
    font-size: 1.25rem;
    margin-left: 20px;
  }
}

.single-view {
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  padding: 20px;
}

.passport-form {
  //padding: 10px;
  //width: 95%;
  display: flex;
  flex-direction: column;
  //margin: 10px 5px 30px;

  .passport-header-preview {
    color: $brand-color;
    font-weight: bold;
    font-size: 1.5em;
    padding: 5px;
    text-align: center;
  }

  .create-button {
    margin: 50px;
  }

  .passport-header {
    color: $brand-color;
    font-weight: bold;
    font-size: 1.5em;
    padding: 5px;
  }

  .form-view {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px;

    .sub-container {
      display: flex;
      flex-direction: row;


    }

    span.preview-span {
      width: 50%;

      padding-bottom: 30px;
      color: #58595B;
    }

    span.value-span {
      padding-bottom: 30px;
      text-align: left;

      color: $brand-color;
    }

  }

  .form-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;


    .upload {
      width: 50%;
    }

    .input-group {

      margin: 10px !important;
    }
  }
}

.passport-table-view {

  border-collapse: collapse;
  width: 100%;
  overflow: hidden;
  box-shadow: 2px 2px 4px #c8c8c8;


  th {
    border: 1px solid #eaecee;
    color: #737373;
    font-weight: normal;
    text-align: center;
    min-width: 30px;
  }

  .unactive:hover {
    background-color: transparent;
  }


  tr {
    border: 1px solid #eaecee;
  }

  tr:first-child:hover {
    background-color: transparent;
  }

  tr:hover {
    background-color: #f3f8ff;
  }

  td {
    min-width: 30px;
    border: 1px solid #eaecee;

    span {
      margin-left: 10px;
    }

    p {

      padding: 3px;
      color: #737373;

    }
  }
}


.passport-list {
  display: flex;
  flex-direction: column;
  height: inherit;


  &__simple-view {
    display: flex;
    flex-direction: column;

    &__item {
      display: flex;
      flex-direction: row;

      &__image {
        width: 31px;
        height: 31px;
      }

      &__content {}

      &__actions {}
    }
  }

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &-actions {
      display: flex;
      flex-direction: row;
      justify-self: start;
      list-style: none;

      &>li {
        padding-right: 20px;
        max-width: 100px;
      }
    }

    &-search {
      width: 300px;
      padding-top: 13px;
      display: flex;
      flex-direction: row;

      &-list {
        list-style-type: none;
        display: inline-flex;

        &-item {
          margin-left: 10px;
        }
      }

      &-form {
        margin-right: 15px;
        margin-left: 10px;

      }
    }
  }

  &-view {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: scroll;
    overflow-x: scroll;

    &-item {
      padding: 10px;
      width: 300px;
      border-radius: 10px;
      border: 1px solid $text-color;
      margin: 10px;

      &-lines {
        padding: 3px;

        &>li {
          display: flex;
          flex-direction: row;
          padding: 10px;
          justify-content: space-between;

          .accent-header {
            color: #BBC2C6;
            font-weight: bold;
            margin-right: 40px;
          }

          .accent-value {
            color: $brand-color;
            font-weight: bold;
          }
        }
      }

      &-selected {
        border: 2px solid $brand-color;


      }

    }

  }

}
.passport-constructor-field{
  margin-bottom: 15px!important;
}
.passport-constructor-box{
  width: 50%;
  margin: 25px auto 30px;
}

.passport-constructor{
  border: 1px solid lightgrey;
  border-radius: 12px;
  padding: 28px !important;
}

.add-variables-row{
  margin: 0 0 15px 0 !important;
}

.add-variables-box{
  border: 1px solid lightgrey;
  border-radius: 12px;
  padding: 20px 20px 15px !important;
}

.passport-block-header{
  width: 50% !important;
  font-weight: Bold;
  font-size: 14px;
  margin: 0 auto;
}

ul.passport-file-block{
  list-style: none;
  padding: 1rem;

  li{
    margin-bottom: 1rem;
    border-bottom: 1px solid $text-color-opacity-15;
    min-height: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 1600px) {
  .passport-constructor-box{
    width: 80%;
  }
}
@media (max-width: 1100px) {
  .passport-constructor-box{
    width: 100%;
  }
}