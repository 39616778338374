@import "constants";

//input[type="text"], input[type="password"] {
//  border: none;
//}

svg{
  pointer-events: none;
}

*:focus {
  outline: none;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
  color: $text-color;
}

.h1, h1 {
  font-size: 2.5rem;
}

.h2, h2 {
  font-size: 2rem;
}

.h3, h3 {
  font-size: 1.75rem;
}

.h4, h4 {
  font-size: 1.5rem;
}

.h5, h5 {
  font-size: 1.25rem;
}

.h6, h6 {
  font-size: 1rem;
}

.help-text {
  color: $text-color;
  font-style: italic;
}

.mr1 {
  margin-right: 1rem!important;
}

.mr2 {
  margin-right: 2rem!important;
}

.mt1 {
  margin-top: 1rem!important;
}

.mt2 {
  margin-top: 2rem!important;
}

.mt3 {
  margin-top: 3rem!important;
}

.mb1 {
   margin-bottom: 1rem!important;
 }

.mb2 {
  margin-bottom: 2rem!important;
}

.mb3 {
  margin-bottom: 3rem!important;
}

.mb4 {
  margin-bottom: 4rem!important;
}

.ml1 {
  margin-left: 1rem!important;
}

.ml2 {
  margin-left: 2rem!important;
}

.op1 {
  opacity: 1;
  transition: opacity 0.3s;
}

.op0_5 {
  opacity: 0.5;
}

.op0_5:hover {
  opacity: 0.8;
}

.w-100{
  width:100%!important;
}

a {
  text-decoration: none;
}

span.link {
  color: $brand-color;
  //letter-spacing: 0.2rem;
  font-size: 80%;

  &:hover {
    text-decoration: underline;
    color: $brand-color-darker;
  }
}

div.center {
  text-align: center;
  justify-content: center;
}

hr {
  border-style: solid;
  border-color: #f7f7f7;
  border-width: 0 0 1px 0;
  margin: 1rem 0;
}

.main-container {
  min-height: calc(100vh - 6rem);
  z-index: 0;
  background-color: $background-color;
  position: absolute;
  top: 6rem;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  min-width: 950px;
}

//div.container {
//  width: calc(100% - 2rem);
//  padding: 0;
//  margin: 1rem;
//  display: inline-table;
//  box-sizing: border-box;
//}

div.container > div.row {
  width: 100%;
  display: inline-block;
  padding: 0;
  box-sizing: border-box;
}

div.col-50, div.col-30, div.col-20, div.col-33, div.col-70, div.col-10 {
  float: left;
  margin: 0 0 1rem 0;
  padding-right: 1rem;
  /*border:1px solid grey;*/
  box-sizing: border-box;
  height: inherit;
  display: table-cell;

  &:last-child {
    padding-right: 0;
  }
}
div.row > div.col-40 {
  width: 40%;
}
div.row > div.col-60 {
  width: 60%;
}
div.row > div.col-50 {
  width: 50%;
}

div.row > div.col-70 {
  width: 70%;
}

div.row > div.col-30 {
  width: 30%;
}

div.row > div.col-20 {
  width: 20%;
}

div.row > div.col-10 {
  width: 10%;
}

div.row > div.col-33 {
  width: 33.33%;
}

div.to-right {
  text-align: right;
  justify-content: right;
}

div.to-center {
  text-align: center;
  justify-content: center;
}

.text-ellipsis-200{
  max-width: 200px;
  white-space: nowrap; /* Запрещаем перенос строк */
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  text-overflow: ellipsis; /* Добавляем многоточие */
}

div.row {

  display: flex;

  .center{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .to-right {
    justify-content: end;
    float: right;
  }

  .to-center {
    justify-content: center;
    text-align: center;
  }

  .to-left {
    justify-content: start;
    float: left;
  }

}

.d-flex.justify-content-center{

  *{
    margin-right: 1rem;

    &:last-child{
      margin-right: 0;
    }
  }

}

.content {
  //background-color: white;
  position: absolute;
  top: 0rem;
  left: 0rem;
  right: 0rem;
  padding-bottom: 1.5rem;
  min-height: calc(100vh - 6rem);
  //box-shadow: 1px 1px 3px lightgrey;
  box-sizing: border-box;

  span.app-version{
    position: absolute;
    bottom: 0;
    left: 1rem;
    display: flex;
    flex-wrap: nowrap;
    color: $text-color-opacity-50;
    font-size: 12px;
  }

  .dashboard-container {
    //position: absolute;
    //top: 6rem;
    //left: 0;
    //right: 0;
    //bottom: 0;
    //overflow-y: scroll;
    //overflow-x: hidden;
    width: 100%;
    //height: max-content;
    margin-top: 6rem;

    div.row {
      width: 100%;
      box-sizing: border-box;
    }

  }

  nav.page-header > ul {
    margin: 0;
    padding: 0 1rem;
  }
}

nav.page-header {
  margin: 0 1rem;
  border-bottom: 1px solid #f7f7f7;
  display: inline-block;
  width: calc(100% - 2rem);

  ul.nav-left {
    float: left;

    li {
      margin-right: 1rem;
      font-size: 110%;
      letter-spacing: 0.1rem;
      line-height: 2rem;
      color: $text-color;
    }

    &.breadcrumb {

      li:first-child:before {
        content: '';
      }

      li:before {
        content: '/';
        margin: 0 0.8rem 0 0;
      }
    }
  }

  ul.nav-right {
    float: right;

    li {
      margin-left: 1rem;
    }
  }

  ul > li {
    height: 2rem;
    margin: 1.5rem 0;

    h5 {
      font-weight: bold;
      letter-spacing: 1px;
      /*margin: 1.5rem 0;*/
      margin: 0;
    }
  }

  li.as-a-btn {
    cursor: pointer;
  }

}

div.row {
  width: calc(100% - 2rem);
  display: inline-block;
  padding: 1rem;
}

div.sub-navbar {
  width: calc(100% - 4rem);
  display: flex;
  padding: 1rem;
  margin: 1rem;
  border-bottom: 1px solid #f7f7f7;
  flex-wrap: wrap;
  justify-content: center;
}

/***
SCROLLBAR FOR MAIN CONTAINER
***/

div.main-container::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
}

div.main-container::-webkit-scrollbar-track-piece {
  background-color: #d0d0d0;
}

div.main-container::-webkit-scrollbar-thumb {
  height: 3rem;
  background-color: $brand-color;
}

div.main-container::-webkit-resizer {
  background-color: #d0d0d0;
}

/***
SCROLLBAR FOR TABLE
***/
table.scrollable::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
}

table.scrollable::-webkit-scrollbar-track-piece {
  background-color: #d0d0d0;
}

table.scrollable::-webkit-scrollbar-thumb {
  height: 3rem;
  background-color: $brand-color;
}

table.scrollable::-webkit-resizer {
  background-color: #d0d0d0;
}

/**
FORMS
*/
//

div.container-700 {

  width: 700px;
  margin: 0 auto 2rem;
  /*
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    form{
        width: 700px;
        margin-top: 2rem;
    }
    */
}

/**
TABLE
*/

table.table {
  color: $text-color;
  width: 100%;

  tr {
    height: 2rem;

    th {
      font-weight: bold;
      background-color: #ececec;
      padding: 2px 8px;
    }

    td {
      line-height: 18px;
      padding: 0 8px;
      border: none;
      vertical-align: middle;
      position: relative;
    }
  }

  tr:nth-child(even) {
    background-color: #e7f5ff;
  }

  button.show-on-hover {
    position: absolute;
    right: 1rem;
    top: 0;
    display: none;
  }

  tr:hover {
    button.show-on-hover {
      display: block;
    }
  }

  &.manual-input-table{
    width: 100%;
    max-height: 20rem;
    overflow-y: scroll;
    display: inline-block;
    border-top: 1px solid #cacaca;
    border-bottom: 1px solid #cacaca;
    padding-right: 0.2rem;
    position: relative;

    label.textarea-placeholder{
      position: absolute;
      left: 200px;
      z-index: 1;
      width: 490px;
      height: 20rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 120%;
      opacity: 0.5;
      pointer-events: none;
    }

    tr {
      height: 12px;

      th {
        font-weight: bold;
        background-color: #f3f3f3;
        padding: 2px 8px;
      }

      td {
        line-height: 14px;
        font-size: 12px;
        padding: 0 8px;
        border: none;
        vertical-align: middle;
        position: relative;

        &:first-child{
          text-align: right;
          letter-spacing: 0.1rem;
        }

        textarea{
          resize: none;
          font-size: 12px;
          line-height: 14px;
          width: 496px;
          background-color: #f1f8ff;
          border: 2px #b3b8ba dashed;
          margin: -2px -0.5rem;
          padding: 0 0.5rem;
        }
      }
    }
  }

}

/**
GROUP
*/

div.justify-content-between > *{
    margin-right: 1rem;
    &:last-child{
      margin-right: 0;
    }
}

div.group {
  background-color: $background-color;
  //border: 1px solid #58595B;
  padding: 3rem 1rem 1rem 1rem;
  border-radius: 0.5rem;
  margin: 2rem 0;
  position: relative;

  label.group-header {
    position: absolute;
    top: 1rem;
    left: 1rem;
    padding: 0 1rem;
    height: 1rem;
    margin: 0;
    line-height: 1rem;
    letter-spacing: 2px;
    font-size: 0.9rem;
    font-style: italic;
  }
}

.field-view {
  width: 100%;
  border-bottom: 1px solid $text-color;
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
}


.flex-row{
  display: flex;
  flex-direction: row;
  &__baseline{
    align-items: baseline;
  }
}


div.eco-container{
  position: relative;
  border-radius: 2rem;
  background-color: $brand-color;

  div.eco-body{
    position: relative;
    padding: 2rem;
    background-color: white;
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;

    &.left-bottom-angle{
      border-bottom-left-radius: 5rem;
    }
    &.right-bottom-angle{
      border-bottom-right-radius: 5rem;
    }

    div.eco-header{
      width: calc(100% + 4rem);
      height: 3rem;
      padding: 0 1rem;
      display: flex;
      align-items: center;
      border-radius: 2rem;
      margin: 0 -2rem;

      .eh-left,.eh-right{
        background-color: #279152;
        color: white;
        position: absolute;
        padding: 0.2rem 2rem;
        min-height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .eh-right{
        right:0;
        border-radius: 0 2rem 2rem 0;
      }

      .eh-left{
        left: 0;
        border-radius: 0 2rem 2rem 0;

      }

    }

  }

  div.eco-footer{
    position: relative;
    background-color: transparent;
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem;
    padding: 2rem;
    color: white!important;

    .btn-bar{
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

  }

}

eco-logo{
  width: 30px;
  height: 30px;
  svg{
    height: inherit;
    width: inherit;
    fill: white;
  }
}

div.ems-logo{
  margin-left: 0.5rem;
  div{
    display: flex;
  }
  svg{
    width:5rem;
    fill: #005ea3!important;
  }
}


hr.light{
  color: $light-grey;
}


div.page-header{
  padding: 2rem;
  background-color: white;
}

.eco-nav-pills{
  .nav-link{
    color: $text-color!important;
    background-color: $background-color!important;
    border: 1px solid $brand-color;

    &.active{
      color: white!important;
      background-color: $brand-color!important;
    }
  }
}

.nav-tabs {
  .nav-link.active {
    color: $text-color !important;
    background-color: $background-color !important;
    border-bottom-color: $background-color !important;
  }
}

.nav{
  .nav-link.active {
    color: $text-color !important;
    background-color: $background-color !important;
    //border-bottom-color: $background-color !important;
  }
}

form {
  svg {
    max-height: 1.4rem;
  }
}


.divided-page {
  min-height: inherit;
  display: flex;
  flex-direction: column;

  .divided-page-header{
    position: relative;
    display: flex;
    &:after{
      content: ' ';
      position:absolute;
      bottom: 0;
      left:2rem;
      right:2rem;
      height: 1px;
      background-color: $light-grey;
    }
  }

  .divided-page-body{
    display: flex;
    flex:1 0 auto;
  }

  .main-content{
    padding:2rem;
    display: flex;
    flex-direction: column;
    background-color: white;
    //position:absolute;

  }

  .info-content{
    padding:2rem;
    display: flex;
    flex-direction: column;
    background-color: $background-color;
  }

  &.page-8-3{
    .main-content{
      flex:8;
    }
    .info-content{
      flex:3;
    }
  }

  &.page-3-1{
    .main-content{
      flex:3;
    }
    .info-content{
      flex:1;
    }
  }

  &.page-1-3{
    .main-content{
      flex:1;
    }
    .info-content{
      flex:3;
    }
  }

  &.page-2-1{
    .main-content{
      flex:2;
    }
    .info-content{
      flex:1;
    }
  }


}

.half-to-half-page{
  min-height: inherit;
  display: flex;
  flex-direction: column;

  .half-to-half-page-header{
    position: relative;
    display: flex;
    &:after{
      content: ' ';
      position:absolute;
      bottom: 0;
      left:2rem;
      right:2rem;
      height: 1px;
      background-color: $light-grey;
    }
  }

  .half-to-half-page-body{
    display: flex;
    flex:1 0 auto;
  }

  .main-content{
    padding:2rem;
    display: flex;
    flex:1;
    flex-direction: column;
    background-color: white;
    //position:absolute;

  }

  .info-content{
    padding:2rem;
    display: flex;
    flex:1;
    flex-direction: column;
    background-color: $background-color;
  }
}
