@import "constants";

div.dict-of-time{

  height: auto;

    div.hours-line{
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 2rem;

      button.hour{
        cursor: pointer;
        border: 1px solid $brand-color;
        position: relative;
        margin:0;
        background-color: white;
        max-width: 4%;
        min-width: 4%;

        span.label{
          position: absolute;
          left: -0.8rem;
          bottom: -1rem;
          font-size: 65%;
        }


        &:hover{
          background-color: $blue-bright-color;
        }


        &.active{
          background-color: $brand-color-lighter;
        }
      }

    }

}