@import "constants";

span.range-slider__wrap{
  width: 100%;

  input[type=range]{
    width: 100%;
    -webkit-appearance: none;
  }

  /*OPERA, CHROME, SAFARI*/

  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.8rem;
    background: #ededed;
    border: none;
    border-radius: 0.4rem;
  }

  input[type=range]::-webkit-slider-container {
    width: 100%;
    height: 0.8rem;
    background: #ddd;
    border: none;
    border-radius: 0.4rem;
  }

  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background: $brand-color;
    margin-top: -0.6rem;
  }

  input[type=range]:focus {
    outline: none;
  }

  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #ccc;
  }


  /*FIREFOX*/
/*
  input[type=range]{
    border: 1px solid white;
  }

  input[type=range]::-moz-range-track {
    width: 300px;
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
  }

  input[type=range]::-moz-range-thumb {
    border: none;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background: @default-color;
  }

  input[type=range]:-moz-focusring{
    outline: 1px solid white;
    outline-offset: -1px;
  }

  input[type=range]:focus::-moz-range-track {
    background: #ccc;
  }
*/

}

