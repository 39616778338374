@import "constants";

/**
BOOTSTRAP EXCEPTIONS AND CUSTOMIZATION
*/
    button.dropdown-item, a.dropdown-item {
      &:active{
        color: $text-color !important;
        background-color: $background-color !important;
      }

    }


form {
  .input-group {
    .dropdown-container {
      position     : relative;
      flex         : 1 1;
      min-width    : 0;
      margin-bottom: 0;
      color        : $brand-color;
      background-color: transparent;
    }
  }
}

div.dropdown-container {
  position        : relative;
  width           : 100%;
  background-color: transparent;

  &.in-table {
    height       : 1.8rem;
    border       : 1px solid grey;
    margin       : 0 0.5rem;
    border-radius: 0.25rem;
    width        : calc(100% - 1rem);

    span.selected-item {
      margin     : 0 1rem;
      height     : 1.8rem;
      line-height: 1.8rem;
      background-color: #efefef;
      padding: 0 1rem;
      border-radius: 0.25rem;
      vertical-align: middle;
      display: flex;
      align-items: center;
    }
  }

  &.datetime-range,
  &.function-list {

    div.dropdown {
      z-index: 5;
      min-width: 170px;
      top      : 2rem;

      ul {
        padding: 0;

        &:first-child {
          padding: 0;
        }

        li {
          padding-left: 1rem;
          border      : none;
          box-sizing  : border-box;
          white-space: nowrap;
        }

        li:hover {
          //border-left     : 0.2rem solid $brand-color;
          padding-left    : 0.8rem;
          background-color: #efefef;
        }
      }

      .year-picker {
        display        : flex;
        justify-content: space-around;
      }

      .month-picker {
        flex-wrap: wrap;
        justify-content: space-evenly;
        display: flex;
        margin: 0 2rem;
        text-align: center;

        button {
          height     : 1.6rem;
          font-size  : 1rem;
          line-height: 0.8rem;
          padding    : 0.3rem;
          width      : 7rem;
          margin     : 0 1rem 1rem 0;
        }
      }
    }
  }

  &.function-list {
    .dropdown {
      right        : 0;
      left         : auto;
      width        : 300px;
      border-radius: 0.25rem;
      border       : 1px solid $brand-color;
      display      : none;

      &.open {
        display: block;
        height : auto !important;
      }
    }
  }

}


div.select-db-datasources{
  display: flex;
  background-color: #f1f1f1;
  /* border-radius: 1rem; */
  margin: -1.5rem -1rem 0 -1rem;

  div.col{
    min-width: 340px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 1rem;
  }

  h2.step{
    font-size: 145%;
    margin: 0 0 1rem 0;
    font-weight: bold;
  }

  div.dropdown-container{
    background-color: inherit!important;
  }

  div.dropdown{
    background-color: inherit!important;
  }
}

div.select-db-datasources-eco-style{
  display: flex;
  height: 100%;
  //background-color: #f1f1f1;
  /* border-radius: 1rem; */
  margin: 0;

  div.col{
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0;

    &.capacity-vars{
      padding-left: 2rem
    }
  }

  h2.step{
    font-size: 145%;
    margin: 0 0 1rem 0;
    font-weight: bold;
  }

  div.dropdown-container{
    height: 100%;
    max-height: 100%;
    padding:0!important;
    background-color: inherit!important;
  }

  div.dropdown{
    max-height: 100%;
    padding: 0!important;
    background-color: inherit!important;
  }


  div.dropdown-container.open {
    background-color: $background-color-lighter;
    border-radius: 0.25rem;
    max-height: 100%;

    &.datetime-range {
      div.dropdown.open {
        height: auto !important;
      }

    }

    &.is-always-open {
      position: relative;
      display : inline-block;

      .dropdown.open {
        height: 100%;
        top       : 0;
        position  : relative;
        box-shadow: none;
        direction : rtl;

        .dropdown-content {
          width: 100%;
          direction: ltr;
        }
      }

    }

    &.select-capacity-variable-container{
      padding-left:2rem;
      height: 100%!important;
      .dropdown {
        border: none !important;
        background-color: #ededed;
        direction: ltr!important;
        //padding-left: 1rem;
        border-radius: 0.25rem;

        ul {
          list-style: square;
          li.datasource-info {
            list-style: square;
            max-height: max-content !important;
            &:hover {
              background: none;
            }
            p{
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
}


div.dropdown-container.open {
  background-color: $background-color-lighter;
  border-radius: 0.25rem;

  &.datetime-range {
    div.dropdown.open {
      height: auto !important;
    }

  }

  &.is-always-open {
    position: relative;
    display : inline-block;

    .dropdown.open {
      top       : 0;
      position  : relative;
      box-shadow: none;
      //direction : rtl;

      .dropdown-content {
        width: 100%;
        direction: ltr;
      }
    }

  }

  &.select-capacity-variable-container{
    height: 0!important;
    .dropdown {
      border: none !important;
      background-color: #ededed;
      direction: ltr!important;
      padding-left: 1rem;
      border-radius: 1rem;

      ul {
        list-style: square;
        li.datasource-info {
          list-style: square;
          max-height: max-content !important;
          &:hover {
            background: none;
          }
          p{
            opacity: 0.8;
          }
        }
      }
    }
  }
}

div.dropdown-container:not(.disabled) span.selected-item:after {
  //content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgNTMuNTQiPjx0aXRsZT5Bc3NldCAxMzY8L3RpdGxlPjxnIGlkPSJMYXllcl8yIiBkYXRhLW5hbWU9IkxheWVyIDIiPjxnIGlkPSJMYXllcl8xLTIiIGRhdGEtbmFtZT0iTGF5ZXIgMSI+PHBhdGggc3R5bGU9ImZpbGw6ICMwMDVlYTM7IiBkPSJNOTksMWEzLjUzLDMuNTMsMCwwLDAtNSwwTDUwLDQ1LjA5LDYsMUEzLjUzLDMuNTMsMCwwLDAsMSw2TDQ3LjQ2LDUyLjUxYTMuNDUsMy40NSwwLDAsMCwyLjUsMSwzLjU5LDMuNTksMCwwLDAsMi41LTFMOTguODksNkEzLjQ3LDMuNDcsMCwwLDAsOTksMVoiPjwvcGF0aD48L2c+PC9nPjwvc3ZnPg==");
  content: url('../sources/images/interface/arrow.svg');
  transform: rotate(0deg);
  position: absolute;
  right: 0.6rem;
  color: $light-grey;
  width: 0.8rem;
  //height: 1rem;
}

div.dropdown-container.open:not(.disabled) span.selected-item:after {
  transform: rotate(180deg);
  right: 0.4rem;
  //right    : 0.4rem;
}

span.selected-item {
  height: 1.6rem;
  color : $brand-color;
  background-color: #ffffff;
  padding: 0 1rem;
  border-radius: 1rem;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

div.dropdown-container span {
  /*width:100%;*/
  display: flex;
  cursor : pointer;
}

div.dropdown.eco-dropdown {
  position                  : relative;
  transition                : height 0.2s;
  background-color          : white;
  overflow-y                : auto;
  box-shadow                : 0 0 2px grey;
  border-bottom-left-radius : 0.25rem;
  border-bottom-right-radius: 0.25rem;

  //ul:first-child {
  //  padding-left: 1rem;
  //  margin      : 0;
  //}

}

div.dropdown{
  z-index                   : 1;
  position                  : absolute;
  top                       : 1.8rem;
  left                      : 0;
  width                     : 100%;
  height                    : 0;
  transition                : height 0.2s;
  background-color          : white;
  overflow-y                : auto;
  box-shadow                : 0 0 2px grey;
  border-bottom-left-radius : 0.25rem;
  border-bottom-right-radius: 0.25rem;

  ul:first-child {
    padding-left: 1rem;
    margin      : 0;
  }

}

div.dropdown.open {
  max-height: 30rem;
  display: block;
  /* min-height: 2rem; */
  height: auto;
  width: auto;
  min-width: 100%;
}

div.dropdown li {
  line-height: 2rem;
  list-style: none;
  //width     : calc(100% - 2rem);
  min-height: 2rem;
  padding: 0 0.6rem;

  &:hover {
    background-color: $background-color;
    //font-weight: bold;
  }

}

div.tree-item {
  height    : 0rem;
  display   : flex;
  width     : 100%;
  transition: height 0.4s;

  &.active{
    span{
      color: $brand-color!important;
    }
  }
}

ul.tree-parent {
  display: none;

  &.open {
    display: block;
  }
}

ul.tree-parent.open>li>div.tree-item {
  height: 2rem;
}

.tree-item--active {
  font-weight    : bold;
  //color          : $brand-color;
  //text-decoration: underline;
}

div.tree-item span {
  cursor     : pointer;
  /*width    : calc(100% - 3rem);*/
  color      : #404040;
  height     : 0rem;
  font-size  : 1rem;
  margin     : 0;
  overflow   : hidden;
  line-height: 1rem;
  padding    : 0.5rem 0;
  opacity    : 0;
  transition : height 0.2s, opacity 0.4s;
}

div.tree-item span:hover {
  text-decoration: underline;
}

button.toggle-tree-item {
  min-width       : 1rem !important;
  width           : 1rem !important;
  height          : 0rem;
  margin          : 0.5rem;
  background-color: white;
  box-shadow      : none;
  border          : 1px solid #bcbcbc;
  color           : #bcbcbc;
  padding         : 0;
  font-size       : 1rem;
  line-height     : 0rem;
  overflow        : hidden;
  opacity         : 0;
  transition      : height 0.2s, opacity 0.4s;
}

ul.tree-parent.open>li>div.tree-item>button.toggle-tree-item{
  height : 1rem;
  opacity: 1;
  display: block;
  white-space: nowrap;
}

ul.tree-parent.open>li>div.tree-item>span {
  height : 2rem;
  opacity: 1;
  display: block;
  white-space: nowrap;
}

div.dropdown::-webkit-scrollbar {
  width : 0.3rem;
  height: 0.3rem;
}

div.dropdown::-webkit-scrollbar-track-piece {
  background-color          : #d0d0d0;
  margin-bottom             : 0.5rem;
  /* padding                : 2rem; */
  border-bottom-right-radius: 0.25rem;
}

div.dropdown::-webkit-scrollbar-thumb {
  height                    : 3rem;
  background-color          : $brand-color;
  border-bottom-right-radius: 0.25rem;
}

div.dropdown::-webkit-resizer {
  background-color: #d0d0d0;
}


ul.device-variable-list {
  background-color: #e9f8ff;
  padding         : 0;
  margin-left     : 3rem;

  li {
    padding: 0 1rem;
    cursor : pointer;


    &:hover {
      background-color: #dff3fa;
      font-weight     : bold;
    }
  }
}

span.dropdown-selected-item-custom::after {
  content: none !important;
}


.dropdown-item.active{
  color: $text-color!important;
  background-color: $background-color!important;
}


/**
TimeZone Select
*/
div.css-yk16xz-control{
  border: 1px solid $brand-color;
  height: 1.8rem;
  border-radius: 2rem;
  div{
    color: $brand-color
  }

}



.react-datepicker-wrapper input{
  border: none!important;
}

.datetime-picker{
  width: inherit;
  height: inherit;
}