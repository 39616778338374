@import 'constants.scss';

div.data-loading:hover{
    cursor: wait!important;
    div{
        cursor: wait!important;
    }
}

.dashboard-grid{
        min-height: 20rem;
}

.react-grid-item{
    //border: 1px solid lightgrey;
    padding: 6px;
    box-sizing: border-box;
    margin: 0;
    border-radius: 0.25rem;
    background-color: white;
    box-shadow: 1px 1px 0.25rem lightgrey;
}

.react-grid-item.resizing{
    border-radius: 12px;
}

.react-grid-item.react-grid-placeholder {
    background: $brand-color!important;
    opacity: 0.2;
    transition-duration: 100ms;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    border-radius: 12px!important;
}

.dashboard-editor-container{
    //background-color: #e7f5ff;
    position: relative;
    width: calc(100% + 2rem);
    margin: 2rem -1rem;
    display: inline-block;

    h5{
        margin-left: 16px;
        letter-spacing: 0.2rem;
    }
}

.drop-zone{
    //background-color: #e7f5ff;
    //min-height: 20rem;
    min-height: 100%;
    border-radius: 0.25rem;
    border: 1px dashed $brand-color;
    background: url('../sources/images/interface/drag-flick.svg') 50% 50% no-repeat $background-color-lighter;
    background-size: 120px 120px;
    margin: 0;
}

.widget-type-list{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}


div.help-block{
    position: relative;
    padding: 0 3rem 0 0;
    font-style: italic;
    p{
        margin: 0;
    }

    &:before{
        content: url('../sources/img/information2.svg');
        position: absolute;
        width: 2rem;
        height: 2rem;
        top: 0;
        right: 0;
    }
}


