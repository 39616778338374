$background-color: #E9F5F3;
$background-color-lighter: #f3fffd;
$brand-color: #209F85;
$brand-color-light: #2cba89;
$brand-color-lighter: #2cba89;
$brand-color-dark: #207e64;
$brand-color-darker: #207e64;
$success: #209F85;
$success-lighter: #30efce;

$danger: #E66D18;
$danger-dark: #c06018;

$dark: #333333;
$dark-light: #4e4e4e;

$warning: #E9CE00;

$text-color: #333;
$text-color-opacity-15: rgba(51, 51, 51, 0.15);
$text-color-opacity-50: rgba(51, 51, 51, 0.5);
$text-color-dark: black;

$light-grey: #c1c1c1;


$blue-color: #7faed1;
$blue-bright-color: #2da8ff;

$chartColor0: #3498db;
$chartColor1: #2ecc71;
$chartColor2: #f1c40f;
$chartColor3: #e67e22;

$eco-green: #32a05f;
$eco-dark-green: #279152;
$eco-grey: #d2d4dc;