
.capacity-tree-view {
  width: 100%;
  overflow-y: auto;
  //height: 750px;
  //overflow-y: scroll;
}

.checkbox {
  width: 20px;
  height: 20px;
  border: 1px solid #d7d8d8;

  input {
    display: none;
  }

  .checkbox-icon {
    padding: 5px;
    height: 10px;
    width: 10px;
    fill: #d7d8d8;
  }
}

.metric-view {
  background-color: antiquewhite;
  padding: 3px;
  margin: 3px;
  border-radius: 10px;
}

.checkbox .selected {
}