@import "constants";

.popover{
  position: absolute;
  z-index: 3;

  button.toggle-popover{
    height: 1.25rem!important;
    min-width: 1.25rem!important;
    max-width: 1.25rem!important;
    font-size: 1rem!important;
    font-style: italic;
    font-weight: bold;
  }

  .popover-content{
    position: absolute;
    display: none;
    background-color: white;
    min-width: 8rem;
    border: 1px solid lightgrey;
    padding: 1rem;
    border-radius: 0.5rem;
  }

  &.open{

    .popover-content{
      display: block;
    }
  }


  &.top,&.top-right,&.top-left{
    top: 0;

    .popover-content{
      top: 1.25rem;
    }
  }

  &.left,&.top-left,&.bottom-left{
    left:0;
    .popover-content{
      left: 1.25rem;
    }
  }

  &.right,&.top-right,&.bottom-right{
    right:0;
    .popover-content{
      right: 1.25rem;
    }
  }
  &.bottom, &.bottom-left,&.bottom-right{
    bottom: 0;
    .popover-content{
      bottom: 1.25rem;
    }
  }

}