div.alert {
    margin: 0.5rem auto;
    border-radius: 0.25rem;
    padding: 1rem;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    min-width: 400px;
;
}

div.alert.alert-danger {
    color: #9c1e00;
    border:1px solid #9c1e00;
}

div.alert.alert-success {
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc;
}