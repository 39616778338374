@import 'constants';

.login-page{
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom: 0;
  //display: flex;
  //justify-content: center;
  //align-items: center;
  background-color: $background-color;
}

.login-container{
  display: flex;
  flex-direction: column;
  height:100%;

  .header{
    display: flex;
    flex: 1;
    background-color: $brand-color;


  }
  .body{
    display: flex;
    flex: 2;
    background-color: $background-color;
    justify-content: center;

    .login-content{
      margin-top: -90px;

      .logo-header{
        display: flex;
        justify-content: space-between;
        align-items: center;

        select{
          width:60px;
        }

      }

      .logo{
         display: flex;
         align-items: center;
        padding-bottom: 20px;
         svg{
           height: 60px;
         }
         label{
           margin-left: 0.8rem;
           font-weight: bold;
           text-align: left;
           color:white;
           font-size: 120%;
         }
       }

      //.logo{
      //  svg{
      //    height: 80px;
      //  }
      //  //padding-bottom: 20px;
      //}

      form{
        min-width: 380px;
      }

    }


  }
}


