@import 'constants';

button.root-capacity-btn{
    padding: 0.1rem 0.6rem;
    background-color: white;
    border: 1px solid $brand-color;
    color: $brand-color;
    border-radius: 0.2rem;
    margin-bottom: 1rem;
    letter-spacing: 0.1rem;
    margin-right: 1rem;
    min-width: calc(100% / 9);
    box-sizing: border-box;
    font-size: 0.9rem;
    line-height: 0.8rem;
    height: 1.6rem;
}

button.root-capacity-btn:last-child{
    margin-right: 0;
}

button.root-capacity-btn.active{
    background-color: #eee;
}

button.root-capacity-btn:hover{
    font-weight: bold;
    border-width: 2px;
}

div.capacity-blocks {
    width: calc(100% - 3rem);
    display: inline-block;
    padding: 1rem 2rem 2rem 1rem;
    margin:0;
}

div.cap-block{
    width: calc(20% - 1rem);
    /* max-width: 20%; */
    /* border: 1px solid $brand-color; */
    padding: 1rem;
    margin: 0 0 1rem 1rem;
    /* border-radius: 1rem; */
    height: 8rem;
    display: inline-block;
    text-align: center;
    float: left;
    box-sizing: border-box;
    position: relative;
}
div.cap-block.btn{
    cursor: pointer;
    box-shadow: 1px 1px 3px #dadada;
    border-radius: 0.5rem;
    border-bottom: 3px #eaeaea double;
    border-right: 3px #eaeaea double;
    border-left: 1px solid #eaeaea;
    border-top: 1px solid #eaeaea;
    /*border: 1px solid #e4e4e4;*/
}
div.cap-block.btn:hover{
    box-shadow: 2px 2px 4px #c8c8c8;
}

div.cap-block label{
text-align: center;
font-weight: bold;
color: $brand-color;
width:100%;
}

div.capacity-image {
/*margin: 0 auto;*/
/*height: 60%;*/
    /*margin: 0 auto;*/
    height: 4rem;
    /*width: auto;*/
    position:absolute;
    bottom: 1rem;
    width: calc(100% - 2rem);
}

div.capacity-image svg {
    margin: 0 auto;
    height: 4rem;
}

div.capacity-image img{
height:100%;
width:100%;
}

ol.breadcrumb{
    align-items: center;

    li{
        cursor: pointer;

        &:hover{
            text-decoration: underline;
        }
    }
}

//
//ol.breadcrumb {
//    color: $brand-color;
//    list-style: none;
//    float: left;
//    width: calc(100% + 1rem);
//    margin: 0 0 1rem 0;
//    padding: 0 0 1rem 0;
//    border-bottom: 1px dotted #f7f7f7
//}
//
//ol.breadcrumb > li {
//    float: left;
//    padding-left:1rem;
//}
//
//ol.breadcrumb > li:first-child:before{
//    content: "";
//    padding: 0;
//}
//ol.breadcrumb > li:last-child{
//    font-weight: bold;
//}
//ol.breadcrumb > li:before{
//    content: " / ";
//    padding-right: 1rem;
//}

ul.capacity-tree, ul.capacity-tree ul, ul.capacity-tree li {
    color: $brand-color;
    position:relative;
}

ul.capacity-tree ul{
    padding: 0 0 0 2rem;
}

ul.capacity-tree li{
    list-style: none;
}

ul.capacity-tree label{
    height:2rem;
    margin:0;
    padding:0;
}

ul.capacity-tree li:before{
    content: ' ';
    position: absolute;
    width: 1rem;
    height: 2rem;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
    border-bottom-left-radius: 0.5rem;
    top: -1.4rem;
    left: -1.5rem;
}

ul.capacity-tree li:first-child:before{
    height: 1rem;
    top: -0.4rem;
}

ul.capacity-tree:before{
    content: ' ';
    border-left: 1px solid grey;
    position: absolute;
    left: calc(1.3rem + 1px);
    top: 0rem;
    width: 3rem;
    height: calc(100% - 1.7rem);
}
