@import "./constants";

.accordion {
  display: flex;
  flex-direction: column;

  .accordion-header {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      width: 80%;
      margin: 0;
      //padding-left: 20px;
      //padding-right: 40px;
      //color: $brand-color;
      //font-weight: bold;
      font-size: 1.5em;

    }
  }

}