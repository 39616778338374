@import "./constants";

.left{
    position: absolute;
    left: 0; 
    top: 0;
    width: 80%;
}

.right{
    //position: absolute;
    margin-right: 0em;
    top: 0;
    width: 18%;
    margin-left: auto;
    font-size: 14px;
    font-family: 'century gothic';
}

.SO-font{
    border-radius: 0.5rem;
    height: 10px;
    width: 10px;
    background-color: #ff7f50;
}
.EL-font{
    border-radius: 0.5rem;
    height: 10px;
    width: 10px;
    background-color: #FFD700;
}
.CH-font{
    border-radius: 0.5rem;
    height: 10px;
    width: 10px;
    background-color: #8A2BE2;
}
.SO-border{
    border-radius: 0.4rem;
    height: auto;
    width: auto;
    border: 2px  #ff7f50 solid;
    padding: 5px;
}
.EL-border{
    border-radius: 0.4rem;
    height: auto;
    width: auto;
    border: 2px #FFD700 solid;
    padding: 5px;
}

.main-label{
    text-align: center;
    font-size: 18px;
    //font-family: 'comic sans ms';
    font-family: 'century gothic';
    font-weight: bold;
}
.h-label{
    font-family: 'century schoolbook';
    font-size: 16px;
}
.r-label{
    text-shadow: rgb(7, 7, 7) 0 0 1px;
    color: #32CD32;
}
